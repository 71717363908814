import React, { lazy, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';

// container components
import Main from './containers/Main';

// feature components
import Callback from './features/login/Callback';
import SelectCompanies from './features/select-companies/SelectCompanies';
import {
  stopSessionTimer,
  removeSessionKeys,
  startSessionTimer,
  validateUserSession,
} from './features/session/session-handler';
import ErrorRedirector from './features/error-handler/ErrorRedirector';

// selectors
import {
  selectIsLoggedIn,
  revokeUserSession,
  selectAccessToken,
  selectSessionExpiryTime,
  selectSessionExpiryTimeInterval,
} from './features/login/token-slice';

import {
  selectCompany,
  setPermissions,
  setSelectedCompany,
} from './features/user/user-slice';

import {
  useGetProfileQuery,
  useGetIpAddressQuery,
} from './services/dev-portal';

// constants
import CONSTANTS from './constants';

// Lazy load the containers for code-splitting
const GettingStartedOverview = lazy(() => import('./containers/GettingStartedOverview'));
const ContactUs = lazy(() => import('./containers/ContactUs'));
const SampleCodes = lazy(() => import('./features/sample-codes'));
const Blog = lazy(() => import('./containers/Blog'));
const CompanyList = lazy(() => import('./containers/CompanyList'));
const CompanyApps = lazy(() => import('./containers/CompanyApps'));
const LandingPage = lazy(() => import('./containers/LandingPage'));
const SwaggerPage = lazy(() => import('./containers/SwaggerPage'));
const ApiProductDetail = lazy(() => import('./containers/ApiProductDetail'));
const CreateCompanyApp = lazy(() => import('./containers/CreateCompanyApp'));
const EditCompanyApp = lazy(() => import('./containers/EditCompanyApp'));
const NotFoundPage = lazy(() => import('./containers/NotFoundPage'));
const ServerErrorPage = lazy(() => import('./containers/ServerErrorPage'));
const ApiDocumentation = lazy(() => import('./containers/ApiDocumentation'));
const InviteDevelopers = lazy(() => import('./containers/InviteDevelopers'));
const CompanyDevelopers = lazy(() => import('./containers/CompanyDevelopers'));
const PartnerOverviewPage = lazy(() => import('@pws-dev-portal/components/src/PartnerOverviewPage'));
const SitemapPage = lazy(() => import('./containers/SitemapPage'));
const AcceptTermsAndConditions = lazy(() => import('./containers/AcceptTermsAndConditions'));
const UnauthorizedPage = lazy(() => import('@pws-dev-portal/components/src/UnauthorizedPage'));
const AutoLogin = lazy(() => import('./containers/AutoLogin'));
const CustomerSuccessVideo = lazy(() => import('@pws-dev-portal/components/src/CustomerSuccessVideo'));
const SettingsPage = lazy(() => import('./containers/Settings'));
const QuotingVideo = lazy(() => import('./containers/QuotingVideo'));
const ApiProductRedirectionPage = lazy(() => import('./containers/ApiProductRedirectionPage'));
const Roadmap = lazy(() => import('./features/roadmap'));

const App = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const ldClient = useLDClient();

  const token = useSelector(selectAccessToken);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const selectedCompany = useSelector(selectCompany);
  const sessionExpiryTime = useSelector(selectSessionExpiryTime);
  const sessionExpiryTimeInterval = useSelector(selectSessionExpiryTimeInterval);

  // Get user profile only when the token is available
  const { data: userProfile } = useGetProfileQuery(null, {
    skip: !token,
  });

  const { data: ipAddress } = useGetIpAddressQuery();

  useEffect(() => {
    // TODO: refactor for better session handling
    // create SessionComponent to handle sessions
    const onHandleLoginUser = () => {
      navigate('/login', {
        state: { referrer: location.pathname !== '/login' ? location.pathname : '/' },
      });
    };
    const onHandleExpireUserSession = () => {
      removeSessionKeys();
    };
    // validates user session from session handler
    validateUserSession({
      loginUser: onHandleLoginUser,
      updateUserSession: onHandleExpireUserSession,
    });
    return function cleanup() {
      // stop running session timer before closing the app
      stopSessionTimer();
    };
  }, []);

  useEffect(() => {
    // TODO: refactor for better session handling
    // create SessionComponent to handle sessions
    if (isLoggedIn) {
      const onHandleRevokeUserSession = () => {
        navigate('/', {
          state: { isSessionExpired: true },
        });
        dispatch(revokeUserSession());
        dispatch(setSelectedCompany(null));
        dispatch(setPermissions([]));
      };
      // start session timer
      startSessionTimer({
        sessionExpiryTime,
        expiryTimeInterval: sessionExpiryTimeInterval,
        revokeUserSession: onHandleRevokeUserSession,
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (ipAddress) {
      let user = {};
      if (isLoggedIn && userProfile && selectedCompany) {
        user = {
          key: userProfile?.userId,
          email: userProfile?.emailId,
          name: userProfile?.firstName,
          custom: {
            // Optional: custom attributes used for segment targeting
            companyName: selectedCompany?.displayName,
            ip: ipAddress.ip,
          },
        };
      } else {
        user = {
          key: 'anonymous',
          custom: {
            // Optional: custom attributes used for segment targeting
            ip: ipAddress.ip,
          },
        };
      }
      // Initialize the client with the user information
      ldClient.identify(user);
    }
  }, [
    ipAddress,
    isLoggedIn,
    userProfile,
    selectedCompany,
  ]);

  const renderPrivateRoute = ({ child }) => (
    isLoggedIn ? child
      : (
        <Navigate
          to={{
            pathname: '/login',
            state: { referrer: location.pathname },
          }}
        />
      ));

  return (
    <div className="App">
      <ErrorRedirector />
      <Routes>
        <Route path="/callback" element={
          <Main>
            <Callback />
          </Main>
        } />
        <Route path="/select-companies" element={
          <Main>
            <SelectCompanies />
          </Main>
        } />
        <Route exact path="business-programs/:slug" element={
          <Main>
            <Blog type={CONSTANTS.BLOG_TYPES.ARTICLES} />
          </Main>
        } />
        <Route exact path="/business-programs" element={
          <Main>
            <Blog type={CONSTANTS.BLOG_TYPES.BUSINESS_PROGRAM} />
          </Main>
        } />
        <Route exact path="blogs/:slug" element={
          <Main>
            <Blog type={CONSTANTS.BLOG_TYPES.ARTICLES} />
          </Main>
        } />
        <Route exact path="/blogs/*" element={
          <Main>
            <Blog />
          </Main>
        } />
        <Route exact path="/sample-codes" element={
          <Main>
            <SampleCodes />
          </Main>
        } />
        <Route exact path="/sample-codes/:section/:language" element={
          <Main>
            <SampleCodes />
          </Main>
        } />
        <Route exact path="/getting-started/overview" element={
          <Main>
            <GettingStartedOverview />
          </Main>
        } />
        <Route exact path="/partner-overview" element={
          <Main>
            <PartnerOverviewPage />
          </Main>
        } />
        <Route exact path="/customer-success-overview" element={
          <Main>
            <CustomerSuccessVideo />
          </Main>
        } />
        <Route exact path="/quoting-overview" element={
          <Main>
            <QuotingVideo />
          </Main>
        } />
        <Route exact path="/:env/companies" element={
          renderPrivateRoute(
            { child: <Main><CompanyList /></Main> },
          )
        } />
        <Route exact path="/:env/companies/:name/apps" element={
          renderPrivateRoute(
            { child: <Main><CompanyApps /></Main> },
          )
        } />
        <Route exact path="/:env/companies/:name/apps/create" element={
          renderPrivateRoute(
            { child: <Main><CreateCompanyApp /></Main> },
          )
        } />
        <Route exact path="/:env/companies/:name/apps/:appName" element={
          renderPrivateRoute(
            { child: <Main><EditCompanyApp /></Main> },
          )
        } />
        <Route exact path="/:env/companies/:name/developers" element={
          renderPrivateRoute(
            { child: <Main><CompanyDevelopers /></Main> },
          )
        } />
        <Route exact path="/:env/companies/:name/developers/invite" element={
          renderPrivateRoute(
            { child: <Main><InviteDevelopers /></Main> },
          )
        } />
        <Route path="/api-products/sales/:name/:apiName" element={
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.SALES}
            />
          </Main>
        } />
        <Route path="/api-products/customer-success/:name/:apiName" element={
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.CUSTOMER_SUCCESS}
            />
          </Main>
        } />
        <Route path="/api-products/ordering/:name/:apiName" element={
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.ORDERING}
            />
          </Main>
        } />
        <Route path="/api-products/quoting/:name/:apiName" element={
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.QUOTING}
            />
          </Main>
        } />
        <Route path="/api-products/sales/:name" element={
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.SALES}
            />
          </Main>
        } />
        <Route path="/api-products/customer-success/:name" element={
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.CUSTOMER_SUCCESS}
            />
          </Main>
        } />
        <Route path="/api-products/ordering/:name" element={
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.ORDERING}
            />
          </Main>
        } />
        <Route path="/api-products/quoting/:name" element={
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.QUOTING}
            />
          </Main>
        } />
        <Route path="/api-products/:category" element={
          <Main>
            <ApiProductRedirectionPage />
          </Main>
        } />
        <Route path="/customer-success/:name" element={
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.CUSTOMER_SUCCESS}
            />
          </Main>
        } />
        <Route path="/customer-success" element={
          <Main>
            <ApiProductRedirectionPage />
          </Main>
        } />
        <Route path="/ordering/:name" element={
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.ORDERING}
            />
          </Main>
        } />
        <Route path="/ordering" element={
          <Main>
            <ApiProductRedirectionPage />
          </Main>
        } />
        <Route exact path="/contact-us" element={
          <Main>
            <ContactUs />
          </Main>
        } />
        <Route exact path="/sitemap" element={
          <Main>
            <SitemapPage />
          </Main>
        } />
        <Route exact path="/page-not-found" element={
          <Main>
            <NotFoundPage />
          </Main>
        } />
        <Route exact path="/server-error" element={
          <Main>
            <ServerErrorPage />
          </Main>
        } />
        <Route exact path="/:env/companies/:name/test-api/:doc" element={
          renderPrivateRoute(
            { child: <Main><SwaggerPage /></Main> },
          )
        } />
        <Route exact path="/:env/companies/:name/test-api" element={
          renderPrivateRoute(
            { child: <Main><ApiDocumentation /></Main> },
          )
        } />
        <Route exact path="/accept-terms-and-conditions" element={
          <Main>
            <AcceptTermsAndConditions />
          </Main>
        } />
        <Route path="/login" element={
          <Main autoLogin={true}>
            <AutoLogin />
          </Main>
        } />
        <Route path="/unauthorized" element={
          <Main>
            <UnauthorizedPage />
          </Main>
        } />
        <Route path="/access-denied" element={
          <Main>
            <LandingPage
              accessDenied={true}
            />
          </Main>
        } />
        <Route exact path="/logout" element={
          <Main>
            <LandingPage />
          </Main>
        } />
        <Route exact path="/:env/companies/:name/settings" element={
          renderPrivateRoute(
            { child: <Main><SettingsPage /></Main> },
          )
        } />
        <Route exact path="/:env/companies/:name/settings/webhooks/new" element={
          renderPrivateRoute(
            { child: <Main><SettingsPage addWebhook={true} /></Main> },
          )
        } />
        <Route exact path="/:env/companies/:name/settings/webhooks/:webhookId/edit" element={
          renderPrivateRoute(
            { child: <Main><SettingsPage editWebhook={true} /></Main> },
          )
        } />
        <Route exact path="/roadmap" element={
          <Main>
            <Roadmap />
          </Main>
        } />
        <Route exact path="/" element={
          <Main>
            <LandingPage />
          </Main>
        } />
        {/* Fallback Route if page not found */}
        <Route element={
          <Main>
            <NotFoundPage />
          </Main>
        } />
      </Routes>
    </div>
  );
};

export default App;
