import { createSlice } from '@reduxjs/toolkit';
import Constants from '../../constants';
import { getPartnerAccessType } from './getPartnerAccessType';

const initialState = {
  isAdmin: false,
  menuList: {},
  // user's selected environment, either
  // from the EnvSelect dropdown or the default
  // environment
  selectedEnv: '',
  // Selected company from select-companies page
  selectedCompany: null,
  isAgency: false,
  isAgencyNxM: false,
  hasAcceptedTerms: false,
  permissions: [],
  isFetchingPermissions: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelectedEnv: (state, action) => {
      state.selectedEnv = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
      state.isAgency = action.payload?.isAgency || false;
      state.isAgencyNxM = action.payload?.isAgencyNxM || false;
    },
    setMenuList: (state, action) => {
      state.menuList = action.payload;
    },
    setHasAcceptedTerms: (state, action) => {
      state.hasAcceptedTerms = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setFetchingPermissions: (state, action) => {
      state.isFetchingPermissions = action.payload;
    },
  },
});

/**
 * Select 'selectedEnv' from the state
 */
export const selectEnv = (state) => state.user.selectedEnv;

/**
 * Selector for user's selected company
 */
export const selectCompany = (state) => state.user.selectedCompany;

/**
 * Selector for user's selected company isAgency
 */
export const selectIsAgency = (state) => state.user.isAgency;

/**
 * Selector for user's selected company isAgencyNxM
 */
export const selectIsAgencyNxM = (state) => state.user.isAgencyNxM;

/**
 * Selector for isAdmin flag.
 * This flag is based on user's role in the selected company
 */
export const selectIsAdmin = (state) => {
  const { selectedCompany } = state.user;
  if (selectedCompany) {
    return selectedCompany.role === Constants.USER_ROLE.ADMIN;
  }
  return false;
};

/**
 * Selector for user's selected company
 */
export const selectHasAcceptedTerms = (state) => state.user.hasAcceptedTerms;

/**
 * List user's active navigation menu
 */
export const getMenuList = (state) => state.user.menuList;

/**
 * Selector for selected companies partner accesss
 * @param {Object} state - Redux state
 * @return {string[]} - List of partner access ("resellers"|"distributors"|"agents")
 */
export const getPartnerAccess = (state) => {
  const { selectedCompany } = state.user;
  return getPartnerAccessType(selectedCompany);
};

/**
 * Selector for "tsr" attribute in the user's selected company
 * @param {Object} state - Redux state
 * @return {("0"|"0.5"|"1"|null)} TSR status value
 */
export const getTsrStatus = (state) => {
  const selectedCompany = state.user?.selectedCompany || {};
  const { tsr = Constants.TSR_STATUS.REVOKED } = selectedCompany;
  return tsr;
};

/**
 * Selector for user's permissions
 */
export const getPermssions = (state) => state.user.permissions;

export const getIsFetchingPermissions = (state) => state.user.isFetchingPermissions;

// export the redux actions
export const {
  setMenuList,
  setPermissions,
  setSelectedEnv,
  setSelectedCompany,
  setHasAcceptedTerms,
  setFetchingPermissions,
} = userSlice.actions;

export default userSlice.reducer;
