import React, { useState, useEffect } from 'react';
import findIndex from 'lodash/findIndex';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NavigationTabsComponent from '@pws-dev-portal/components/src/NavigationTabs';

import TabList from './navigation-tabs.json';
import { selectIsLoggedIn } from '../features/login/token-slice';
import {
  selectEnv,
  setMenuList,
  selectCompany,
  selectIsAgency,
  selectHasAcceptedTerms,
  getPermssions,
} from '../features/user/user-slice';

import Constants from '../constants';

function NavigationTabs() {
  // selectors
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const selectedEnv = useSelector(selectEnv);
  const selectedCompany = useSelector(selectCompany);
  const isAgency = useSelector(selectIsAgency);
  const hasAcceptedTerms = useSelector(selectHasAcceptedTerms);

  const userPermissions = useSelector(getPermssions);

  // hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const featureFlags = useFlags();

  const [tabs, setTabs] = useState(TabList);

  // Change the "my-apps" link dynamically
  // based on the user's selected company
  useEffect(() => {
    const myAppsIdx = findIndex(tabs, { mainNavCode: 'my-apps' });
    const orderingIdx = findIndex(tabs, { mainNavCode: 'ordering' });

    let linkToApps = '/select-companies';
    let subLinkToApps = TabList[myAppsIdx].subNav;

    // clone TabList
    const tabList = TabList.slice();
    if (selectedCompany && selectedEnv) {
      const { name } = selectedCompany;

      // get sub links from 'my-apps/my-apps' tab id
      const mySubAppsIdx = findIndex(subLinkToApps, { subNavCode: 'my-apps' });

      // update sub links from 'my-apps/my-apps' tab
      if (mySubAppsIdx !== -1) {
        linkToApps = `/${selectedEnv}/companies/${name}/apps`;
        subLinkToApps[mySubAppsIdx] = {
          ...subLinkToApps[mySubAppsIdx],
          linkTo: linkToApps,
        };
      }

      // get sub links from 'my-apps/test-api' tab id
      const mySubTestApiIdx = findIndex(subLinkToApps, { subNavCode: 'test-api' });

      // update sub links from 'my-apps/test-api' tab
      if (mySubTestApiIdx) {
        subLinkToApps[mySubTestApiIdx] = {
          ...subLinkToApps[mySubTestApiIdx],
          linkTo: `/${selectedEnv}/companies/${name}/test-api`,
        };
      }
    } else {
      subLinkToApps = null;
    }

    const linkToOrdering = TabList[orderingIdx];
    // if selected company is agency show quoting
    if (isAgency && isLoggedIn) {
      linkToOrdering.displayText = 'Quoting and Ordering';
    } else {
      linkToOrdering.displayText = 'Ordering';
    }

    tabList[myAppsIdx] = {
      ...TabList[myAppsIdx],
      linkTo: linkToApps,
      // hide my apps sub nvaigation if company is not yet selected
      subNav:
        (selectedCompany
          && hasAcceptedTerms
          && location.pathname !== `/${selectedEnv}/companies`)
          || (location.pathname === '/sitemap')
          ? subLinkToApps
          : [],
    };
    setTabs(tabList);
  }, [location, selectedEnv, selectedCompany, hasAcceptedTerms]);

  const onHandleMenuList = (menuList) => {
    dispatch(setMenuList(menuList));
  };

  const filteredTabs = tabs.filter((t) => {
    // display if menu's feature flag is on
    if (t.featureFlagKey && featureFlags) {
      const isON = featureFlags[t.featureFlagKey];
      const hasPermission = userPermissions.some((p) => [
        Constants.PARTNER_ACCESS.AGENTS,
        Constants.PARTNER_ACCESS.AGENTS_NXM,
      ].includes(p));
      // show only if it's an agency
      if (t.mainNavCode === 'roadmap') return isON && hasPermission;
      return isON;
    }
    return true;
  });

  return (
    <NavigationTabsComponent
      env={selectedEnv || 'dev'}
      tabs={filteredTabs}
      location={location}
      loggedIn={isLoggedIn}
      selectedCompany={selectedCompany?.name}
      hasAcceptedTerms={hasAcceptedTerms}
      onTabClick={() => { }}
      onHandleMenuList={onHandleMenuList}
    />
  );
}

export default NavigationTabs;
